<section class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="counter-max-wide">
            <div class="section-title white-title">
                <span>Why Choose Us</span>
                <h2>We Have a Lot of Skills in Cyber Security</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-authentication"></i>
                            <h2>365</h2>
                            <h3>Clients Protection</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-reliability"></i>
                            <h2>1000</h2>
                            <h3>Trusted Organizations</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-web-protection"></i>
                            <h2>567</h2>
                            <h3>Website Protection</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-innovation"></i>
                            <h2>100%</h2>
                            <h3>Innovative Technology</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>